<template>
  <v-sheet
      :color="BLOCK_COLOR_PARAM(block , 'tiles_card_background')"
      class="fill-height d-flex flex-column"
      :style="`border-radius : ${config.block_radius}px`"
      style="position: relative"
  >
    <div>
      <ws-img
          v-if="item.img"
          :src="item.img"
          :height="config.image_height || 200"
          width="100%"
          :vertical-position="item.img_vertical || 50"
          :horizontal-position="item.img_horizontal || 50"
          :image-style="imageStyle"
          :contain="item.img_contain"
          auto-size
          :trigger-resize="imageResizeTrigger"
      />
    </div>

    <div class="flex-grow-1" >
      <div
          class="d-flex flex-column fill-height" style="padding: 24px;" :style="`padding-bottom : ${34 + (config.block_radius/2)}px`">
        <div>
          <h3 :style="BLOCK_TEXT_CSS(block , config.style , 'tiles' , 'title')" >{{ item.title }}</h3>
          <h4 :style="BLOCK_TEXT_CSS(block , config.style , 'tiles' , 'text')" class="mt-2">{{ item.text }}</h4>
          <h4 :style="BLOCK_TEXT_CSS(block , config.style , 'tiles' , 'description')" class="mt-2" v-html="PARSE_NEW_LINES(item.description)"/>
        </div>

        <div  v-if="item.style_button && item.style_button !== 'text' " class="flex-grow-1" />

        <ws-link
            :href="item.link || null"
            :disabled="$store.state.avalon.editorMode || ['enroll_on_course','sidebar','dialog'].includes(item.action)"
            new-window
        >

          <avalon-button
              v-if="item.button_text"
              @click="$emit('action')"
              :block-style="block.config.style"
              :button-style="item.style_button"
              primary-background-param="tiles_button_primary_bg"
              primary-text-color-param="tiles_button_primary"
              secondary-background-param="tiles_button_secondary_bg"
              secondary-text-color-param="tiles_button_secondary"
              :text="item.button_text"
              :block="block"
              :radius="item.button_radius"
              :icon-right="item.button_icon"
              class="mt-2"
              fill-area

          />
          <template v-else-if="config.button_text">

            <h4 v-if="config.button_text" @click="$emit('action')"
                :style="BLOCK_TEXT_CSS(block , config.style , 'tiles' , 'button')"
                class="pointer mt-2"
            >
              {{ item.button_text || config.button_text }}
            </h4>
          </template>
        </ws-link>

      </div>
    </div>




  </v-sheet>
</template>

<script>
export default {
  name: "blockTilesDesign1",
  props : {
    item : {
      type : Object,
      default() { return {} }
    },
    config  : {
      type : Object,
      default() { return {} }
    },
    cols : {},
    block : {
      type : Object,
      default() { return { config : {}} }
    }
  },
  data() {
    return {
      imageResizeTrigger : 0
    }
  },
  computed : {
    noCardBackground() {
      return this.CONFIG_PARAM_COLOR('styles' , this.config.style , 'tiles_card_background') === this.CONFIG_PARAM_COLOR('styles' , this.config.style , 'bg')
    },
    imageStyle() {
      return `border-top-left-radius: ${this.config.block_radius}px !important; border-top-right-radius: ${this.config.block_radius}px !important`
    }
  },
  watch : {
    cols() {
      this.imageResizeTrigger++
    }
  }

}
</script>

<style scoped>

</style>